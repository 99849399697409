<template>
	<div class="animated fadeIn" v-if="$screen.width <= 576">
		<div class="container-mobile">
			<div>
				<b-alert :show="error && error.status === 509 ? true : false" variant="warning">
					<h4 class="alert-heading">{{ FormMSG(1, 'Informations') }}</h4>
					<div class="d-flex flex-row justify-content-start align-items-center w-100" style="gap: 8px">
						<div>
							<component :is="getLucideIcon('AlertTriangle')" color="#EA4E" :size="40" />
						</div>
						<div>{{ error.message }}</div>
					</div>
				</b-alert>
			</div>
			<ticket-form
				:useComponent="this.$props.useComponent"
				v-if="form == 2"
				:action="action"
				:users="users"
				:edit-data="expense"
				@ticket-form-mobile:submitted="handleSubmit"
			/>
			<!-- faire attention à ce qu'on ajoute @ticket:invalid="isInvalidateFields" -->
			<invoice-form v-if="form == 3" :users="users" :action="action" :useComponent="this.$props.useComponent" />
			<mileage-form
				v-if="form == 1"
				:users="users"
				:action="action"
				:edit-data="expense"
				@mileage-form-mobile:submitted="handleSubmit"
				:useComponent="this.$props.useComponent"
			/>
			<!--			<b-row>-->
			<!--				<b-col>-->
			<!--					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">-->
			<!--						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">-->
			<!--							{{ FormMSG(168, 'Green details') }}-->
			<!--						</legend>-->
			<!--						<div class="pt-3 pb-4">-->
			<!--							<co-two-clap-->
			<!--								:parent-id="+currTicketRef"-->
			<!--								:entity-type="1"-->
			<!--								:for-create="true"-->
			<!--								:is-submit="submitCoTwoClap"-->
			<!--								hide-btn-reviewed-->
			<!--								@co-two-clap:end-submit="handleCoTwoClapEndSubmit"-->
			<!--							/>-->
			<!--						</div>-->
			<!--					</fieldset>-->
			<!--				</b-col>-->
			<!--			</b-row>-->
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { store } from '@/store';
import moment from 'moment';
import { isNil } from '@/shared/utils';

import languageMessages from '@/mixins/languageMessages';
import TicketForm from '@/components/MyExpenseMobile/TicketForm';
import InvoiceForm from '@/components/MyExpenseMobile/InvoiceForm';
import MileageForm from '@/components/MyExpenseMobile/MileageForm';
import { getUsers } from '../../cruds/users.crud';
import globalMixin from '@/mixins/global.mixin';
import { EXPENSE_TYPE, INIT_DATA_CARBON } from '@/shared/constants';

export default {
	name: 'ExpenseForm',
	mixins: [languageMessages, globalMixin],
	props: {
		useComponent: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	components: { TicketForm, InvoiceForm, MileageForm },
	data() {
		return {
			type: 0,
			expenseId: 0,
			action: 0,
			users: [],
			expense: {
				date: `${moment(new Date()).format('YYYY-MM-DD')}T00:00:00Z`
			},
			error: {
				status: null,
				message: ''
			},
			isImageCurrentlyUploading: false
		};
	},
	computed: {
		...mapGetters({
			currentNewExpense: 'expenses/currentNewExpense'
		}),
		currentExpense: {
			get() {
				return this.$store.getters['expenses/focusedExpenseItem'];
			},
			set(expense) {
				this.$store.dispatch('expenses/setFocusExpenseItem', expense);
			}
		}
	},
	created() {
		const { form, action, type, expenseId } = this.$route.params;
		this.getAllUsers();
		this.form = parseInt(form, 10);
		this.type = parseInt(type, 10);
		this.expenseId = expenseId;

		if (action === 'n') {
			this.action = 0;
			this.createExpense(this.form, this.type);
		} else if (action === 'u') {
			this.action = 1;
			this.expense = this.currentExpense;
		}
	},
	methods: {
		...mapActions({
			initNewExpense: 'expenses/initNewExpense'
		}),
		async getAllUsers() {
			let forTimeSheet = true;
			this.users = [
				...this.users,
				...[...(await getUsers({ myProject: true }, 0, -1, 0, 0, 0, '', '', forTimeSheet))].map((_user) => {
					return { value: _user.id, name: _user.fullName };
				})
			];
		},
		async createExpense(form, type) {
			this.expense = {
				...this.expense,
				type
			};
			switch (form) {
				case 2: // ticket
					const { department } = store.state.myProfile;
					this.expense = {
						...this.expense,
						department
					};
					await this.addExpense(this.expense);
					await this.getExpense().then((expense) => {
						this.expense = expense;
					});

					break;
				case 3: // invoice
					// console.log('invoice');

					break;
				case 1: // mileage
					await this.addExpense(this.expense).then((expense) => {
						this.expense = expense;
					});
					await this.getExpense();

					break;
			}
		},
		async getExpense() {
			return new Promise((resolve, reject) => {
				this.expense = this.currentNewExpense;

				// TODO must fix by Julis
				if (this.expense.date === 'T00:00:00Z') {
					this.expense = {
						...this.expense,
						date: moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00Z'
					};
				}

				setTimeout(() => {
					resolve(this.expense);
					reject(false);
				}, 200);
			});
		},
		handleSubmit(payload) {
			// console.log({ handleSubmit: payload });
			// this.$bus.$emit('expense-form-mobile:submitted', payload);
		},
		/**
		 * @param {Boolean} status
		 */
		handleImageCurrentlyUploading(status) {
			console.log({ handleImageCurrentlyUploading: status });
			this.isImageCurrentlyUploading = status;
		},
		async addExpense(expense) {
			const carbon = {
				...INIT_DATA_CARBON,
				coTwoCategory: expense.type === EXPENSE_TYPE.TRAVEL ? 4 : 0,
				numberOfVehicule: expense.type === EXPENSE_TYPE.TRAVEL ? 1 : 0,
				reason: expense.type === EXPENSE_TYPE.TRAVEL ? 0 : 0,
				type: expense.type === EXPENSE_TYPE.TRAVEL ? 6 : 0
			};

			await this.initNewExpense({
				...expense,
				subCategory: 6,
				carbon
			}).catch((e) => {
				let path = `/myexpenses`;
				const error = this.getErrorCode(e);

				if (!_.isNil(error)) {
					this.error = error;

					path += `?ec=${this.error.status}`;
					// this.$emit('expense-service-main:error', this.error);
				}

				// const error = e.message.match(new RegExp('[0-9]{3}', 'g'));
				// this.error.status = true;

				// if (!isNil(error) && error.length === 1) {
				// 	this.error.code = error[0];
				// }
				this.$router.push({ path });

				return;
			});
		},
		resetError() {
			this.error = {
				status: null,
				message: ''
			};
		},
		getErrorCode(error) {
			if (!_.isNil(error) && !_.isNil(error.graphQLErrors) && error.graphQLErrors.length > 0) {
				return {
					status: error.graphQLErrors[0].status,
					message: error.graphQLErrors[0].message.replace(new RegExp('[0-9]{3}\:', 'g'), '').trim()
				};
			}

			return null;
		}
	}
};
</script>
