<template>
	<div class="my-expense-mobile">
		<div class="cursor-pointer title" @click="$router.go(-1)">
			<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
				<span><arrow-left color="#FFFFFF" :size="26" /></span>{{ captionTitle }}
			</h1>
		</div>
		<div class="form" :class="{ 'mt-16': $isPwa() }">
			<b-form-group v-if="useComponent" :label="FormMSG(11, 'Select Person')" label-for="new-expense_ticket-ref">
				<b-form-select
					size="md"
					v-model="selectedUser"
					:options="users"
					value-field="value"
					text-field="name"
					disabled-field="notEnabled"
				></b-form-select>
			</b-form-group>
			<div class="cursor-pointer add-ticket-form" @click="redirectToTicket">
				<div class="icon"><plus-circle :size="20" /></div>
				<div class="label">{{ FormMSG(3, 'Create Invoice without a purchase order') }}</div>
			</div>
			<div class="choose-po" v-if="optionPurchaseOrders.length > 0">
				<v-select
					:placeholder="FormMSG(4, 'Choose a purchase order')"
					:options="optionPurchaseOrders"
					@option:selected="handlePoChange"
					@input="removePo"
				>
				</v-select>
			</div>
			<div class="content" style="margin-top: 24px" v-else>
				<div class="card-expense">
					<div class="wrapper">
						<div class="resume">
							<div class="second-col w-100">
								<div class="title">
									{{ FormMSG(7, 'No purchase order') }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="poListsItems.length > 0">
				<div class="add-invoice-po">
					<div class="title">
						{{ poSelectedItem.description }}
					</div>
					<div class="cursor-pointer check-actions" @click="switchCheckAll">
						<div class="d-flex justify-content-end align-items-center" v-if="checkAll">
							<span class="pr-2">{{ FormMSG(9, 'All') }}</span
							><check-square :size="16" />
						</div>
						<div class="d-flex justify-content-end align-items-center" v-else>
							<span class="pr-2">{{ FormMSG(10, 'None') }}</span
							><square :size="16" />
						</div>
					</div>
				</div>
				<div class="content">
					<div class="card-expense">
						<div class="header">
							<div class="resume">
								<div class="first-col" style="width: 8%"></div>
								<div class="second-col" style="min-width: 52%"></div>
								<div class="third-col text-center" style="min-width: 40%">
									<div class="title" style="margin-top: 2px; width: 50%">Total</div>
									<div class="title" style="margin-top: 2px; width: 50%">VAT</div>
								</div>
							</div>
							<div class="card-divider" style="margin-top: 0" />
						</div>
						<div v-for="(po, index) in poListsItems" :key="index">
							<div class="wrapper" :style="`${index > 0 ? 'padding-top: 0;' : ''}`">
								<div class="resume">
									<div class="first-col" style="width: 8%">
										<b-form-checkbox size="lg" v-model="collectionId" :value="po.id" @change="handleSelectedItem" />
									</div>
									<div class="second-col" style="min-width: 52%">
										<div class="title" style="margin-top: 2px">
											{{ po.name }}
										</div>
									</div>
									<div class="third-col text-center" style="min-width: 40%">
										<div class="title" style="margin-top: 2px; width: 50%">
											{{ currentCurrency(po.priceTotal) }}
										</div>
										<div class="title" style="margin-top: 2px; width: 50%">
											{{ currentCurrency(po.priceVatTotal) }}
										</div>
									</div>
								</div>
								<div class="card-divider" v-if="index < poListsItems.length - 1" />
							</div>
						</div>
					</div>
				</div>
				<b-row class="footer-fixed">
					<b-col cols="12">
						<b-button block size="lg" type="button" variant="primary" class="mb-2" @click="handleSubmit" v-if="collectionId.length > 0">
							{{ FormMSG(5, 'Save') }}
						</b-button>
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import languageMessages from '@/mixins/languageMessages';
import { ArrowLeft, PlusCircle, CheckSquare, Square } from 'lucide-vue';
import { getPoItems } from '@/cruds/po-item.crud';
import { isNil } from '@/shared/utils';
import { store } from '@/store';
import { rendCurrency } from '~helpers';

export default {
	name: 'InvoiceForm',
	mixins: [languageMessages],
	components: {
		ArrowLeft,
		PlusCircle,
		CheckSquare,
		Square
	},
	props: {
		useComponent: {
			type: Boolean,
			required: false,
			default: false
		},
		users: {
			type: Array,
			required: true
		},
		action: {
			type: Number, // ADD = 0, UPDATE = 1
			required: true,
			default: 0
		}
	},
	data() {
		return {
			checkAll: false,
			poListsItems: [],
			poSelectedItem: null,
			collectionId: [],
			poCopyIds: [],
			selectedUser: 0
		};
	},
	async created() {
		const { action, expenseId } = this.$route.params;
		if (action === 'n') {
			this.selectedUser = parseInt(store.state.myProfile.id, 10);
			this.isUpdate = false;
		} else if (action === 'u') {
			this.selectedUser = parseInt(this.expense.user.id, 10);
			this.isUpdate = true;
		}
		await this.getPurchaseOrdersNotConsumed();
	},
	computed: {
		...mapGetters({
			purchaseOrders: 'purchaces/purchaseOrdersNotConsumed'
		}),
		captionTitle() {
			return this.action === 0 ? this.FormMSG(1, 'Add Invoice') : this.FormMSG(2, 'Update Invoice');
		},
		captionCheckAll() {
			return this.checkAll ? this.FormMSG(6, 'Select All') : this.FormMSG(8, 'Unselect All');
		},
		optionPurchaseOrders() {
			return this.purchaseOrders.map((po) => ({
				code: po.id,
				label: `${po.description} (${po.departmentName}) - ${this.currentCurrency(po.amount)}`,
				po
			}));
		}
	},

	methods: {
		...mapActions({
			getPurchaseOrdersNotConsumed: 'purchaces/getPurchaseOrdersNotConsumed',
			setNewInvoiceFromPoForSelectedPoItems: 'expenses/setNewInvoiceFromPoForSelectedPoItems'
		}),
		goBack() {
			const path = '/myexpenses';
			this.$router.push({ path });
		},
		redirectToTicket() {
			this.$router.push({ path: '/myexpenses', query: { form: 2, action: 'n', type: 1, back: 'i' } });
		},
		async handlePoChange({ code, po }) {
			this.collectionId = [];
			this.poCopyIds = [];
			this.checkAll = false;

			this.poListsItems = await getPoItems(code, true);
			this.poSelectedItem = po;
			this.poListsItems.forEach((po) => {
				this.collectionId.push(parseInt(po.id, 10));
			});

			this.poCopyIds = this.collectionId;
		},
		removePo(payload) {
			if (isNil(payload)) {
				this.poListsItems = [];
				this.poSelectedItem = null;
				this.collectionId = [];
				this.poCopyIds = [];
				this.checkAll = false;
			}
		},
		currentCurrency(amount) {
			return rendCurrency(amount);
		},
		switchCheckAll() {
			if (this.checkAll) {
				this.collectionId = this.poCopyIds;
			} else {
				this.collectionId = [];
			}
			// end
			this.checkAll = !this.checkAll;
		},
		handleSelectedItem(payload) {
			if (payload.length === 0) {
				this.checkAll = true;
			}
		},
		async handleSubmit() {
			this.collectionId = this.collectionId.map((id) => {
				return parseInt(id, 10);
			});
			await this.setNewInvoiceFromPoForSelectedPoItems({
				id: this.poSelectedItem.id,
				poItems: this.collectionId
			});
			this.$router.go(-1);
		}
	}
};
</script>
