var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-expense-mobile" },
    [
      _c(
        "div",
        {
          staticClass: "cursor-pointer title",
          on: {
            click: function ($event) {
              _vm.action === 1 && !_vm.analyzingImage ? _vm.goForce() : ""
            },
          },
        },
        [
          _c(
            "h1",
            {
              staticClass: "main-page-title",
              class: { "is-pwa": _vm.$isPwa() },
            },
            [
              _vm.action === 1 && !_vm.analyzingImage
                ? _c(
                    "span",
                    [
                      _c(_vm.getLucideIcon("ArrowLeft"), {
                        tag: "component",
                        attrs: { color: "#FFFFFF", size: 26 },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(_vm._s(_vm.captionTitle) + "\n\t\t"),
            ]
          ),
        ]
      ),
      _vm.analyzingImage
        ? _c(
            "span",
            [
              _c("loading", {
                attrs: {
                  active: _vm.analyzingImage,
                  loader: "bars",
                  "is-full-page": "",
                },
                on: {
                  "update:active": function ($event) {
                    _vm.analyzingImage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "form", class: { "mt-16": _vm.$isPwa() } },
        [
          _c(
            "b-form",
            { on: { submit: _vm.submitForm } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-start sub-title" },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(8, "Attach Receipt")) +
                      "\n\t\t\t"
                  ),
                ]
              ),
              _vm.expense.comment && _vm.expense.comment.length > 0
                ? _c("div", { staticClass: "expense-comment" }, [
                    _c("p", { staticClass: "px-2" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.expense.comment) +
                          "\n\t\t\t\t"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(9, "Ticket Reference"),
                    "label-for": "expense-ticket-ref",
                    "label-cols": 12,
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "expense-ticket-ref",
                      type: "text",
                      disabled: "",
                    },
                    model: {
                      value: _vm.expense.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.expense, "id", $$v)
                      },
                      expression: "expense.id",
                    },
                  }),
                  _c("div", { staticClass: "info-message" }, [
                    _c(
                      "span",
                      [
                        _c(_vm.getLucideIcon("Info"), {
                          tag: "component",
                          attrs: { size: 16 },
                        }),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.FormMSG(
                            10,
                            "Write down this number in your Receipt."
                          )
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm.useComponent
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(39, "Select Person"),
                        "label-for": "new-expense_ticket-ref",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          size: "md",
                          options: _vm.users,
                          "value-field": "value",
                          "text-field": "name",
                          disabled: _vm.isUpdate,
                        },
                        model: {
                          value: _vm.selectedUser,
                          callback: function ($$v) {
                            _vm.selectedUser = $$v
                          },
                          expression: "selectedUser",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("CapturesPackage", {
                        attrs: {
                          "can-upload-pdf": "",
                          multiple: "",
                          "preview-mode": "button",
                          "parent-type": "expense_item",
                          "parent-sub-type": "ticket",
                          "parent-id": _vm.expense.id,
                          pictures: _vm.expense.images,
                          "preview-modal-options": {
                            zIndex: 1041,
                            hideLikeDislike: true,
                            hideCommentButton: true,
                            hideDisLikeButton: true,
                          },
                        },
                        on: {
                          change: _vm.handleImgsTaken,
                          "upload-image:loading": _vm.handleUploadImgStatus,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.errorOcr
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-danger mt-4",
                      attrs: { role: "alert" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-items-center",
                          staticStyle: { color: "#721c24" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pr-3",
                              staticStyle: { "font-size": "1.5rem" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-exclamation-triangle",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "mt-0" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    33,
                                    "An error was occured during analyzing data or no billing information detected in this image."
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "divider pt-3" }),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(12, "General Information")) +
                    "\n\t\t\t"
                ),
              ]),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("label", { attrs: { for: "date-input" } }, [
                    _vm._v(_vm._s(_vm.FormMSG(13, "Choose a date"))),
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: { id: "date-input", type: "text", readonly: "" },
                        on: { click: _vm.handleDateInput },
                        model: {
                          value: _vm.dateFormatted,
                          callback: function ($$v) {
                            _vm.dateFormatted = $$v
                          },
                          expression: "dateFormatted",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              id: "ticket-date-picker",
                              "button-only": "",
                              right: "",
                              locale: _vm.currentLang,
                              "aria-controls": "date-input",
                              "close-button": "",
                            },
                            on: { context: _vm.onContext },
                            model: {
                              value: _vm.expense.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.expense, "date", $$v)
                              },
                              expression: "expense.date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mt-3",
                  attrs: {
                    label: _vm.FormMSG(14, "Description"),
                    "label-for": "expense-description",
                    "label-cols": 12,
                  },
                },
                [
                  _c("b-form-textarea", {
                    class: { "is-invalid": _vm.$v.expense.description.$error },
                    attrs: { id: "expense-description", rows: "3" },
                    model: {
                      value: _vm.$v.expense.description.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.expense.description, "$model", $$v)
                      },
                      expression: "$v.expense.description.$model",
                    },
                  }),
                  _vm.$v.expense.description.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                25,
                                "Please, length should be at least"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.minLengthDescription) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(42, "VAT"),
                            "label-for": "type-of-vat",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "text",
                              options: _vm.taxOptions,
                              reduce: (option) => option.value,
                              "get-option-label": (option) =>
                                option.item ? option.item.factor : "",
                              placeholder: _vm.FormMSG(43, "Select a VAT"),
                              selectable: _vm.selectableItem,
                            },
                            on: { input: _vm.handleVatChange },
                            scopedSlots: _vm._u([
                              {
                                key: "option",
                                fn: function (option) {
                                  return [
                                    option.disabled
                                      ? _c(
                                          "div",
                                          { staticClass: "text-bold" },
                                          [_vm._v(_vm._s(option.text))]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "text-color ml-3" },
                                          [
                                            _vm._v(
                                              _vm._s(option.text) +
                                                " - " +
                                                _vm._s(option.item.name) +
                                                " (" +
                                                _vm._s(option.item.factor) +
                                                "%)"
                                            ),
                                          ]
                                        ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.vatType,
                              callback: function ($$v) {
                                _vm.vatType = $$v
                              },
                              expression: "vatType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(15, "Total Amount"),
                            "label-cols": 12,
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                class: {
                                  "is-invalid":
                                    _vm.isInvalidAmountTotal ||
                                    _vm.$v.expense.amountTotal.$error,
                                },
                                attrs: {
                                  type: "number",
                                  step: "0.01",
                                  placeholder: "0",
                                },
                                on: { input: _vm.handleAmountTotalChange },
                                model: {
                                  value: _vm.$v.expense.amountTotal.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.expense.amountTotal,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.expense.amountTotal.$model",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("currency-svg", {
                                        attrs: {
                                          color: "#06263E",
                                          opacity: "0.85",
                                          width: "15",
                                          height: "15",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.isInvalidAmountTotal ||
                              _vm.$v.expense.amountTotal.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.isInvalidAmountTotal
                                              ? _vm.FormMSG(
                                                  44,
                                                  "Must be greater than VAT amount"
                                                )
                                              : _vm.$v.expense.amountTotal
                                                  .$error
                                              ? _vm.FormMSG(
                                                  4510,
                                                  "Please, value must be other than 0"
                                                )
                                              : ""
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(46, "VAT amount"),
                            "label-cols": 12,
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                class: _vm.isInvalidAmountTotal
                                  ? "is-invalid"
                                  : "",
                                attrs: {
                                  type: "number",
                                  step: "0.01",
                                  placeholder: "0",
                                  min: "0",
                                  disabled: _vm.vatAmountDisabled,
                                },
                                model: {
                                  value: _vm.expense.amountVat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.expense, "amountVat", $$v)
                                  },
                                  expression: "expense.amountVat",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    [
                                      _c("currency-svg", {
                                        attrs: {
                                          color: "#06263E",
                                          opacity: "0.85",
                                          width: "15",
                                          height: "15",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isInvalidAmountTotal
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        47,
                                        "Must be smaller than total amount"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-form-group", {
                staticClass: "pt-1",
                attrs: { label: _vm.FormMSG(18, "Type of paiement") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ ariaDescribedby }) {
                      return [
                        _c("b-form-radio-group", {
                          staticClass: "paiement-type",
                          attrs: {
                            size: "lg",
                            "aria-describedby": ariaDescribedby,
                            options: _vm.optionPaiementTypes,
                            name: "payment-type",
                            stacked: "",
                          },
                          model: {
                            value: _vm.expense.paiementType,
                            callback: function ($$v) {
                              _vm.$set(_vm.expense, "paiementType", $$v)
                            },
                            expression: "expense.paiementType",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("div", { staticClass: "divider pt-1" }),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(19, "Expense Details")) +
                    "\n\t\t\t"
                ),
              ]),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("department-selector", {
                        attrs: {
                          "edit-data": _vm.expense,
                          "is-submitted": _vm.isSubmitted,
                          "is-new": _vm.isNew,
                        },
                        on: {
                          change: _vm.handleDepartmentAndCatChange,
                          "department:selector:invalid": _vm.isInvalidateFields,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "fieldset",
                      {
                        staticClass:
                          "card-inside fix-fieldset-card-inside-padding-bottom",
                      },
                      [
                        _c("legend", { staticClass: "card-inside" }, [
                          _vm._v(_vm._s(_vm.FormMSG(20, "Supplier"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c("supplier-selector", {
                              attrs: {
                                label: "",
                                title: `${
                                  !_vm.isSupplierUpdate
                                    ? _vm.FormMSG(250, "Add supplier details")
                                    : _vm.FormMSG(
                                        251,
                                        "Update supplier details"
                                      )
                                }`,
                                placeholder: _vm.FormMSG(22, "Filter by name"),
                                addlabel: _vm.FormMSG(23, "Add"),
                                "update-label": _vm.FormMSG(24, "Update"),
                                "supplier-id": _vm.supplierId,
                                "ocr-suppliers": _vm.suppliersInImages,
                              },
                              on: {
                                change: _vm.handleSupplierChange,
                                "supplier:unselected": _vm.onRemoveSupplier,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticStyle: { "padding-bottom": "100px !important" } },
                [
                  _c("b-col", [
                    _c(
                      "fieldset",
                      {
                        staticClass: "my-0 py-0 pb-0",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _c(
                          "legend",
                          {
                            class: `${
                              _vm.$screen.width <= 576
                                ? "card-inside"
                                : "scheduler-border"
                            } ${
                              _vm.$screen.width > 576
                                ? "text-color-rhapsody-in-blue-2"
                                : ""
                            }`,
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(168, "Green details")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "pb-3" },
                          [
                            _c("co-two-clap", {
                              ref: "coTwoClapTicket",
                              attrs: {
                                "parent-id": +_vm.expense.id,
                                "entity-type": 1,
                                "for-create": true,
                                "external-carbon-data": _vm.carbonData,
                                "hide-location-menu": "",
                                "hide-premise-menu": "",
                                "hide-btn-reviewed": "",
                              },
                              on: {
                                "co-two-clap:change": _vm.handleCoTwoClapChange,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "footer-fixed" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            block: "",
                            size: "lg",
                            type: "submit",
                            variant: "primary",
                            disabled:
                              _vm.analyzingImage || _vm.isImageUploadLoading,
                          },
                        },
                        [
                          _vm.isImageUploadLoading
                            ? _c("b-spinner", {
                                attrs: {
                                  variant: "light",
                                  label: "img loading spinner",
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(21, "Save")) +
                              "\n\t\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.action === 0
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                block: "",
                                size: "lg",
                                type: "button",
                                variant: "light",
                                disabled: _vm.analyzingImage,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCancel(_vm.expense.id)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(27, "Cancel")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.$isPwa()
        ? _c("capture", {
            attrs: { "dynamic-crop-size": "", "edit-data": _vm.expense },
            on: { change: _vm.handleImgsTaked },
            model: {
              value: _vm.isCaptureModalOpen,
              callback: function ($$v) {
                _vm.isCaptureModalOpen = $$v
              },
              expression: "isCaptureModalOpen",
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            id: "ocr-issue-modal",
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(78, "Error"),
            "header-bg-variant": "danger",
            "header-border-variant": "danger",
            "ok-variant": "light",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.ocrIssueModal = false
            },
          },
          model: {
            value: _vm.ocrIssueModal,
            callback: function ($$v) {
              _vm.ocrIssueModal = $$v
            },
            expression: "ocrIssueModal",
          },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.FormMSG(
                  77,
                  "An error has occurred during created the expense, please retry again or contact your administrator."
                )
              ) +
              "\n\t"
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }