var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-expense-mobile" }, [
    _c(
      "div",
      {
        staticClass: "cursor-pointer title",
        on: {
          click: function ($event) {
            return _vm.$router.go(-1)
          },
        },
      },
      [
        _c(
          "h1",
          { staticClass: "main-page-title", class: { "is-pwa": _vm.$isPwa() } },
          [
            _c(
              "span",
              [_c("arrow-left", { attrs: { color: "#FFFFFF", size: 26 } })],
              1
            ),
            _vm._v(_vm._s(_vm.captionTitle) + "\n\t\t"),
          ]
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "form", class: { "mt-16": _vm.$isPwa() } },
      [
        _vm.useComponent
          ? _c(
              "b-form-group",
              {
                attrs: {
                  label: _vm.FormMSG(11, "Select Person"),
                  "label-for": "new-expense_ticket-ref",
                },
              },
              [
                _c("b-form-select", {
                  attrs: {
                    size: "md",
                    options: _vm.users,
                    "value-field": "value",
                    "text-field": "name",
                    "disabled-field": "notEnabled",
                  },
                  model: {
                    value: _vm.selectedUser,
                    callback: function ($$v) {
                      _vm.selectedUser = $$v
                    },
                    expression: "selectedUser",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "cursor-pointer add-ticket-form",
            on: { click: _vm.redirectToTicket },
          },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [_c("plus-circle", { attrs: { size: 20 } })],
              1
            ),
            _c("div", { staticClass: "label" }, [
              _vm._v(
                _vm._s(
                  _vm.FormMSG(3, "Create Invoice without a purchase order")
                )
              ),
            ]),
          ]
        ),
        _vm.optionPurchaseOrders.length > 0
          ? _c(
              "div",
              { staticClass: "choose-po" },
              [
                _c("v-select", {
                  attrs: {
                    placeholder: _vm.FormMSG(4, "Choose a purchase order"),
                    options: _vm.optionPurchaseOrders,
                  },
                  on: {
                    "option:selected": _vm.handlePoChange,
                    input: _vm.removePo,
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "content", staticStyle: { "margin-top": "24px" } },
              [
                _c("div", { staticClass: "card-expense" }, [
                  _c("div", { staticClass: "wrapper" }, [
                    _c("div", { staticClass: "resume" }, [
                      _c("div", { staticClass: "second-col w-100" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(7, "No purchase order")) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
        _vm.poListsItems.length > 0
          ? _c(
              "div",
              [
                _c("div", { staticClass: "add-invoice-po" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.poSelectedItem.description) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "cursor-pointer check-actions",
                      on: { click: _vm.switchCheckAll },
                    },
                    [
                      _vm.checkAll
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end align-items-center",
                            },
                            [
                              _c("span", { staticClass: "pr-2" }, [
                                _vm._v(_vm._s(_vm.FormMSG(9, "All"))),
                              ]),
                              _c("check-square", { attrs: { size: 16 } }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end align-items-center",
                            },
                            [
                              _c("span", { staticClass: "pr-2" }, [
                                _vm._v(_vm._s(_vm.FormMSG(10, "None"))),
                              ]),
                              _c("square", { attrs: { size: 16 } }),
                            ],
                            1
                          ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    { staticClass: "card-expense" },
                    [
                      _vm._m(0),
                      _vm._l(_vm.poListsItems, function (po, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "div",
                            {
                              staticClass: "wrapper",
                              style: `${index > 0 ? "padding-top: 0;" : ""}`,
                            },
                            [
                              _c("div", { staticClass: "resume" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "first-col",
                                    staticStyle: { width: "8%" },
                                  },
                                  [
                                    _c("b-form-checkbox", {
                                      attrs: { size: "lg", value: po.id },
                                      on: { change: _vm.handleSelectedItem },
                                      model: {
                                        value: _vm.collectionId,
                                        callback: function ($$v) {
                                          _vm.collectionId = $$v
                                        },
                                        expression: "collectionId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "second-col",
                                    staticStyle: { "min-width": "52%" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "title",
                                        staticStyle: { "margin-top": "2px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(po.name) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "third-col text-center",
                                    staticStyle: { "min-width": "40%" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "title",
                                        staticStyle: {
                                          "margin-top": "2px",
                                          width: "50%",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.currentCurrency(po.priceTotal)
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "title",
                                        staticStyle: {
                                          "margin-top": "2px",
                                          width: "50%",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.currentCurrency(
                                                po.priceVatTotal
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              index < _vm.poListsItems.length - 1
                                ? _c("div", { staticClass: "card-divider" })
                                : _vm._e(),
                            ]
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "b-row",
                  { staticClass: "footer-fixed" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.collectionId.length > 0
                          ? _c(
                              "b-button",
                              {
                                staticClass: "mb-2",
                                attrs: {
                                  block: "",
                                  size: "lg",
                                  type: "button",
                                  variant: "primary",
                                },
                                on: { click: _vm.handleSubmit },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(5, "Save")) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "resume" }, [
        _c("div", { staticClass: "first-col", staticStyle: { width: "8%" } }),
        _c("div", {
          staticClass: "second-col",
          staticStyle: { "min-width": "52%" },
        }),
        _c(
          "div",
          {
            staticClass: "third-col text-center",
            staticStyle: { "min-width": "40%" },
          },
          [
            _c(
              "div",
              {
                staticClass: "title",
                staticStyle: { "margin-top": "2px", width: "50%" },
              },
              [_vm._v("Total")]
            ),
            _c(
              "div",
              {
                staticClass: "title",
                staticStyle: { "margin-top": "2px", width: "50%" },
              },
              [_vm._v("VAT")]
            ),
          ]
        ),
      ]),
      _c("div", {
        staticClass: "card-divider",
        staticStyle: { "margin-top": "0" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }